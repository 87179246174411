@font-face {
  font-family: 'ftr';
  src: url('./fonts/FuturaPT-Book.woff2') format('woff2');
}
@font-face {
  font-family: 'crd';
  src: url('./fonts/Cardo-Regular.woff2') format('woff2');
}

body {
  margin: 0;
  font-family: "ftr", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #292b2f;
  color: #fff;
}

body,
#root {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
div.ant-typography,
p.ant-typography,
.ant-form-item-label > label,
.ant-empty {
  color: #fff;
}
.ant-input {
  background-color: #292b2f;
  color: #fff;
  border-color: #1890ff;
}
.ant-input-affix-wrapper.ant-input-password {
  background-color: #292b2f;
  color: #fff;
  border-color: #1890ff;
}
.ant-input-affix-wrapper.ant-input-password .anticon {
  color: #1890ff;
}

.dark-hover:hover {
  color: #292b2f;
  border-color: #292b2f;
}

.ant-list-split .ant-list-item {
  border-bottom: 1px dashed #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.click-container {
  outline: none;
}

.user-horz-nav {
  padding: 2rem 0;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.user-horz-nav .link {
  flex: 0 0 auto;
  margin: 0 2rem;
  border-bottom: 0.25rem solid transparent;
  transition: border-bottom 300ms;
}
.user-horz-nav .link.link-active {
  border-bottom: 0.25rem solid #fff;
}

.main-nav-link {
  border-bottom: 0.25rem solid transparent;
  transition: border-bottom 300ms;
}
.main-nav-link.link-active {
  border-bottom: 0.25rem solid #fff;
}

.vert-menu > * {
  margin: 1rem;
}



